import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 24,
    title: "Operazioni CRUD con React e MongoDB",
    desc: "",
    img: "/blog-image/crud.png",
    page: "blog/react-CRUD",
    data: "04 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Le operazioni CRUD (Create, Read, Update, Delete) sono fondamentali nella realizzazione di applicazioni web moderne.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3>Introduzione</h3>
                                    <p>
                                        Le operazioni CRUD (Create, Read,
                                        Update, Delete) sono fondamentali nella
                                        realizzazione di applicazioni web
                                        moderne. In questo articolo, ti
                                        mostreremo come implementare queste
                                        operazioni in un'applicazione React,
                                        utilizzando MongoDB come sistema di
                                        gestione del database. Attraverso questo
                                        tutorial, potrai imparare le basi per
                                        sviluppare app più dinamiche,
                                        interattive e reattive.
                                    </p>

                                    <h3>Tecnologie Utilizzate:</h3>

                                    <ul>
                                        <li>
                                            <b>MongoDB:</b> Un database NoSQL
                                            orientato ai documenti.
                                        </li>
                                        <li>
                                            <b>Express:</b> Un framework per
                                            applicazioni web Node.js, essenziale
                                            per creare API.
                                        </li>
                                        <li>
                                            <b>React:</b> Una libreria
                                            JavaScript per costruire interfacce
                                            utente.
                                        </li>
                                        <li>
                                            <b>Node.js:</b> Un runtime
                                            JavaScript lato server.
                                        </li>
                                    </ul>

                                    <ol>
                                        <li>
                                            <h5>Setup del Progetto:</h5>
                                            <p>
                                                Prima di tutto, crea un nuovo
                                                progetto React e inizializza un
                                                nuovo progetto Node.js.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`
npx create-react-app crud-app
cd crud-app
npm init -y
`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>
                                                Installazione delle Dipendenze:
                                            </h5>
                                            <p>
                                                Installiamo le dipendenze
                                                necessarie.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`
npm install express mongoose cors
`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>Configurazione del Server:</h5>
                                            <p>
                                                Crea un file server.js nella
                                                radice del tuo progetto e
                                                configura il tuo server Express
                                                e MongoDB.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`const express = require('express');
const mongoose = require('mongoose');
const cors = require('cors');

const app = express();

app.use(cors());
app.use(express.json());

mongoose.connect('mongodb://localhost:27017/crudDB', {
    useNewUrlParser: true,
    useUnifiedTopology: true
});

app.listen(3001, () => {
    console.log('Server is running on port 3001');
});`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <br />
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <li>
                                            <h5>Creazione del Modello:</h5>
                                            <p>
                                                Definisci un modello Mongoose
                                                per interagire con il database
                                                MongoDB.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`const Schema = mongoose.Schema;

const ItemSchema = new Schema({
    name: String
});

const Item = mongoose.model('Item', ItemSchema);

app.get('/items', async (req, res) => {
    const items = await Item.find();
    res.json(items);
});

// Aggiungi qui i tuoi endpoint per Create, Update e Delete.`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>
                                                Costruzione del Componente
                                                React:
                                            </h5>
                                            <p>
                                                Ora, creiamo un componente React
                                                che interfaccia con il nostro
                                                server.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
        const res = await axios.get('http://localhost:3001/items');
        setItems(res.data);
        };
        fetchItems();
    }, []);

    return (
        <div>
            {items.map(item => (
                <div key={item._id}>{item.name}</div>
            ))}
        </div>
    );
}

export default App;`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                    </ol>
                                    <p>
                                        A questo punto, hai un'applicazione
                                        React che può leggere dati da un
                                        database MongoDB attraverso un server
                                        Express. Dovresti estendere il tuo
                                        server e componente React per supportare
                                        le operazioni di creazione,
                                        aggiornamento e cancellazione.
                                    </p>
                                    <h3>
                                        Implementare operazioni CRUD complete:
                                    </h3>
                                    <p>
                                        Per implementare completamente le
                                        operazioni CRUD, dovrai aggiungere
                                        ulteriori endpoint al tuo server Express
                                        e metodi al tuo componente React.
                                        Ricorda di gestire adeguatamente gli
                                        stati e di aggiornare l'interfaccia
                                        utente in modo reattivo ogni volta che
                                        il database viene aggiornato.
                                    </p>
                                    <p>
                                        Se hai bisogno di aiuto per il tuo
                                        progetto non esitare a contattarci.
                                        Avrai una squadra di esperti pronta a
                                        darti una mano. Buon coding.
                                    </p>
                                    <br />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
